import '../styles/main.scss';
import 'overlayscrollbars/styles/overlayscrollbars.css';
import { OverlayScrollbars, SizeObserverPlugin, ClickScrollPlugin, ScrollbarsHidingPlugin } from 'overlayscrollbars';
// import CookieConsent from '../../components/cookie-consent/cookie-consent';

OverlayScrollbars.plugin([SizeObserverPlugin, ClickScrollPlugin, ScrollbarsHidingPlugin]);
// Cookie consent
// (async () => {
//   const cookieOptions = await import(`../../components/cookie-consent/cookie-options-${document.documentElement.lang}.json`);
//   // eslint-disable-next-line no-unused-vars
//   const consent = new CookieConsent(cookieOptions);
// })();
